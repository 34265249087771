export const HostingIcon = () => (
  <svg
    width="80"
    height="81"
    viewBox="0 0 80 81"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2749_17717)">
      <path
        d="M70 53H10C5.85786 53 2.5 56.3579 2.5 60.5V70.5C2.5 74.6421 5.85786 78 10 78H70C74.1421 78 77.5 74.6421 77.5 70.5V60.5C77.5 56.3579 74.1421 53 70 53Z"
        fill="#B0C1D4"
      />
      <path
        d="M70 55.5C71.3261 55.5 72.5979 56.0268 73.5355 56.9645C74.4732 57.9021 75 59.1739 75 60.5V70.5C75 71.8261 74.4732 73.0979 73.5355 74.0355C72.5979 74.9732 71.3261 75.5 70 75.5H10C8.67392 75.5 7.40215 74.9732 6.46447 74.0355C5.52678 73.0979 5 71.8261 5 70.5V60.5C5 59.1739 5.52678 57.9021 6.46447 56.9645C7.40215 56.0268 8.67392 55.5 10 55.5H70ZM70 50.5H10C7.34784 50.5 4.8043 51.5536 2.92893 53.4289C1.05357 55.3043 0 57.8478 0 60.5L0 70.5C0 73.1522 1.05357 75.6957 2.92893 77.5711C4.8043 79.4464 7.34784 80.5 10 80.5H70C72.6522 80.5 75.1957 79.4464 77.0711 77.5711C78.9464 75.6957 80 73.1522 80 70.5V60.5C80 57.8478 78.9464 55.3043 77.0711 53.4289C75.1957 51.5536 72.6522 50.5 70 50.5Z"
        fill="#B0C1D4"
      />
      <path d="M30 65.5H65V70.5H30V65.5Z" fill="#B0C1D4" />
      <path d="M65 65.5H30V70.5H65V65.5Z" fill="#66798F" />
      <path
        d="M20 60.5C18.6739 60.5 17.4021 61.0268 16.4645 61.9645C15.5268 62.9021 15 64.1739 15 65.5C15 66.8261 15.5268 68.0979 16.4645 69.0355C17.4021 69.9732 18.6739 70.5 20 70.5C21.3261 70.5 22.5979 69.9732 23.5355 69.0355C24.4732 68.0979 25 66.8261 25 65.5C25 64.1739 24.4732 62.9021 23.5355 61.9645C22.5979 61.0268 21.3261 60.5 20 60.5Z"
        fill="#5E9C76"
      />
      <path
        d="M22 38.0002C19.5286 38.0404 17.1419 37.1004 15.3617 35.3858C13.5814 33.6711 12.5525 31.3214 12.5 28.8502C12.5163 27.0637 13.0553 25.321 14.0504 23.8372C15.0455 22.3533 16.4533 21.1933 18.1 20.5002L18.95 20.1002L19.35 19.2502C20.1342 17.6248 21.363 16.2548 22.8939 15.2991C24.4249 14.3434 26.1953 13.841 28 13.8502H30L30.8 12.7002C31.8589 11.2141 33.2577 10.0026 34.8798 9.16662C36.5018 8.33068 38.3002 7.89453 40.125 7.89453C41.9498 7.89453 43.7482 8.33068 45.3702 9.16662C46.9923 10.0026 48.3911 11.2141 49.45 12.7002L50.25 13.8502H52.15C53.9614 13.8395 55.7381 14.3468 57.2707 15.3124C58.8032 16.278 60.0278 17.6616 60.8 19.3002L61.2 20.1502L62.05 20.5502C63.6609 21.2624 65.032 22.4244 65.9988 23.8967C66.9655 25.3689 67.4868 27.089 67.5 28.8502C67.4475 31.3214 66.4186 33.6711 64.6383 35.3858C62.8581 37.1004 60.4714 38.0404 58 38.0002H22Z"
        fill="#C2E8FF"
      />
      <path
        d="M40 10.5C41.4215 10.4931 42.8235 10.8305 44.0861 11.4836C45.3487 12.1367 46.4343 13.0859 47.25 14.25L48.85 16.55H51.65H52.2C53.5034 16.5733 54.7744 16.9601 55.8698 17.6668C56.9652 18.3736 57.8415 19.3721 58.4 20.55L59.2 22.25L60.95 23C62.1098 23.4915 63.1062 24.3022 63.8232 25.3378C64.5402 26.3735 64.9483 27.5915 65 28.85C64.9476 30.6581 64.1822 32.3721 62.8708 33.618C61.5594 34.8638 59.8084 35.5404 58 35.5H22C20.1916 35.5404 18.4406 34.8638 17.1292 33.618C15.8178 32.3721 15.0524 30.6581 15 28.85C15.018 27.5514 15.4158 26.2864 16.1444 25.2113C16.873 24.1362 17.9005 23.2979 19.1 22.8L20.85 22.05L21.6 20.5C22.1585 19.3221 23.0348 18.3236 24.1302 17.6168C25.2256 16.9101 26.4966 16.5233 27.8 16.5H28.35H31.15L32.75 14.2C33.5712 13.0451 34.6592 12.1056 35.9215 11.4614C37.1838 10.8172 38.5829 10.4874 40 10.5ZM40 5.50003C37.764 5.48629 35.5581 6.01611 33.5723 7.04388C31.5864 8.07165 29.88 9.56659 28.6 11.4H28C25.7255 11.3839 23.4932 12.0146 21.5634 13.2185C19.6335 14.4224 18.0856 16.1501 17.1 18.2C15.0061 19.0883 13.218 20.5701 11.9564 22.4626C10.6947 24.3551 10.0146 26.5756 10 28.85C10.0526 31.9842 11.345 34.9698 13.594 37.1533C15.8431 39.3368 18.8656 40.5402 22 40.5H58C61.1344 40.5402 64.1569 39.3368 66.406 37.1533C68.655 34.9698 69.9474 31.9842 70 28.85C69.9757 26.5841 69.2911 24.3745 68.03 22.4917C66.7689 20.609 64.9861 19.1349 62.9 18.25C61.9288 16.1814 60.387 14.4331 58.4561 13.2107C56.5251 11.9884 54.2853 11.3429 52 11.35H51.4C50.1144 9.52582 48.4056 8.0406 46.4201 7.02171C44.4346 6.00283 42.2316 5.48068 40 5.50003Z"
        fill="#C2E8FF"
      />
    </g>
    <defs>
      <clipPath id="clip0_2749_17717">
        <rect
          width="80"
          height="80"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
