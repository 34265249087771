export const IdentityIcon = () => (
  <svg
    width="80"
    height="51"
    viewBox="0 0 80 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.07"
      d="M75 3C75.663 3 76.2989 3.26339 76.7678 3.73223C77.2366 4.20107 77.5 4.83696 77.5 5.5V45.5C77.5 46.163 77.2366 46.7989 76.7678 47.2678C76.2989 47.7366 75.663 48 75 48H5C4.33696 48 3.70107 47.7366 3.23223 47.2678C2.76339 46.7989 2.5 46.163 2.5 45.5V5.5C2.5 4.83696 2.76339 4.20107 3.23223 3.73223C3.70107 3.26339 4.33696 3 5 3H75ZM75 1.75H5C4.00578 1.75111 3.05259 2.14655 2.34957 2.84957C1.64655 3.55259 1.25111 4.50578 1.25 5.5V45.5C1.25111 46.4942 1.64655 47.4474 2.34957 48.1504C3.05259 48.8535 4.00578 49.2489 5 49.25H75C75.9942 49.2489 76.9474 48.8534 77.6504 48.1504C78.3534 47.4474 78.7489 46.4942 78.75 45.5V5.5C78.7489 4.50578 78.3534 3.5526 77.6504 2.84958C76.9474 2.14656 75.9942 1.75111 75 1.75Z"
      fill="black"
    />
    <path
      d="M75 3H5C3.61929 3 2.5 4.11929 2.5 5.5V45.5C2.5 46.8807 3.61929 48 5 48H75C76.3807 48 77.5 46.8807 77.5 45.5V5.5C77.5 4.11929 76.3807 3 75 3Z"
      fill="white"
    />
    <path
      d="M5 3H75C75.663 3 76.2989 3.26339 76.7678 3.73223C77.2366 4.20107 77.5 4.83696 77.5 5.5V38H2.5V5.5C2.5 4.83696 2.76339 4.20107 3.23223 3.73223C3.70107 3.26339 4.33696 3 5 3Z"
      fill="#BAF4D6"
    />
    <path
      d="M10 45.5C11.3807 45.5 12.5 44.3807 12.5 43C12.5 41.6193 11.3807 40.5 10 40.5C8.61929 40.5 7.5 41.6193 7.5 43C7.5 44.3807 8.61929 45.5 10 45.5Z"
      fill="#BAF4D6"
    />
    <path
      d="M20.0005 45.5C21.3812 45.5 22.5005 44.3807 22.5005 43C22.5005 41.6193 21.3812 40.5 20.0005 40.5C18.6198 40.5 17.5005 41.6193 17.5005 43C17.5005 44.3807 18.6198 45.5 20.0005 45.5Z"
      fill="#BAF4D6"
    />
    <path
      d="M30 45.5C31.3807 45.5 32.5 44.3807 32.5 43C32.5 41.6193 31.3807 40.5 30 40.5C28.6193 40.5 27.5 41.6193 27.5 43C27.5 44.3807 28.6193 45.5 30 45.5Z"
      fill="#BAF4D6"
    />
    <path
      d="M40 45.5C41.3807 45.5 42.5 44.3807 42.5 43C42.5 41.6193 41.3807 40.5 40 40.5C38.6193 40.5 37.5 41.6193 37.5 43C37.5 44.3807 38.6193 45.5 40 45.5Z"
      fill="#BAF4D6"
    />
    <path
      d="M70.0002 45.5C71.381 45.5 72.5002 44.3807 72.5002 43C72.5002 41.6193 71.381 40.5 70.0002 40.5C68.6195 40.5 67.5002 41.6193 67.5002 43C67.5002 44.3807 68.6195 45.5 70.0002 45.5Z"
      fill="#BAF4D6"
    />
    <path
      d="M60 45.5C61.3807 45.5 62.5 44.3807 62.5 43C62.5 41.6193 61.3807 40.5 60 40.5C58.6193 40.5 57.5 41.6193 57.5 43C57.5 44.3807 58.6193 45.5 60 45.5Z"
      fill="#BAF4D6"
    />
    <path
      d="M21.25 20.5C24.7018 20.5 27.5 17.7018 27.5 14.25C27.5 10.7982 24.7018 8 21.25 8C17.7982 8 15 10.7982 15 14.25C15 17.7018 17.7982 20.5 21.25 20.5Z"
      fill="#33C481"
    />
    <path
      d="M21.25 23C15 23 10 26.3579 10 30.5C10 31.163 10.2634 31.7989 10.7322 32.2678C11.2011 32.7366 11.837 33 12.5 33H30C30.663 33 31.2989 32.7366 31.7678 32.2678C32.2366 31.7989 32.5 31.163 32.5 30.5C32.5 26.3579 27.5 23 21.25 23Z"
      fill="#33C481"
    />
    <path
      d="M67.5 14.4424H42.5C41.1193 14.4424 40 15.5617 40 16.9424C40 18.3231 41.1193 19.4424 42.5 19.4424H67.5C68.8807 19.4424 70 18.3231 70 16.9424C70 15.5617 68.8807 14.4424 67.5 14.4424Z"
      fill="#33C481"
    />
    <path
      d="M50 25.019H42.5C41.1193 25.019 40 26.1383 40 27.519C40 28.8998 41.1193 30.019 42.5 30.019H50C51.3807 30.019 52.5 28.8998 52.5 27.519C52.5 26.1383 51.3807 25.019 50 25.019Z"
      fill="#33C481"
    />
    <path
      d="M67.3079 25.019H59.8079C58.4271 25.019 57.3079 26.1383 57.3079 27.519C57.3079 28.8998 58.4271 30.019 59.8079 30.019H67.3079C68.6886 30.019 69.8079 28.8998 69.8079 27.519C69.8079 26.1383 68.6886 25.019 67.3079 25.019Z"
      fill="#33C481"
    />
  </svg>
);
