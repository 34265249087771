export const DownloadIcon = () => (
  <svg
    width="80"
    height="81"
    viewBox="0 0 80 81"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1584_10112)">
      <path
        d="M5 78C3.625 78 2.5 76.88 2.5 75.5V55.5L11.765 5.125C11.975 3.88 13.01 3 14.235 3H65.77C66.995 3 68.03 3.88 68.235 5.09L77.54 55.945L77.5 75.5C77.5 76.88 76.375 78 75 78H5Z"
        fill="#A3B2C4"
      />
      <path
        d="M65.765 5.5L65.78 5.58L75 55.955V75.5H5V55.955L14.235 5.5H65.765ZM65.765 0.5H14.235C11.79 0.5 9.705 2.265 9.305 4.68L0 55.5V75.5C0 78.26 2.24 80.5 5 80.5H75C77.76 80.5 80 78.26 80 75.5V55.5L70.695 4.68C70.295 2.265 68.21 0.5 65.765 0.5Z"
        fill="#E1EBF2"
      />
      <path
        d="M65 60.5C62.24 60.5 60 62.74 60 65.5C60 68.26 62.24 70.5 65 70.5C67.76 70.5 70 68.26 70 65.5C70 62.74 67.76 60.5 65 60.5Z"
        fill="white"
      />
      <path
        d="M5.08496 55.5H74.915L65.78 5.58L65.765 5.5H14.235L5.08496 55.5Z"
        fill="#E1EBF2"
      />
      <path
        d="M26.0352 33H32.5002V3H47.5002V33H53.9652L40.0002 46.965L26.0352 33Z"
        fill="#8BB7F0"
      />
      <path
        d="M45 5.5V30.5V35.5H47.93L40 43.43L32.07 35.5H35V30.5V5.5H45ZM50 0.5H30V30.5H20L40 50.5L60 30.5H50V0.5Z"
        fill="#8BB7F0"
      />
      <path d="M35 0.5H45V10.5H35V0.5Z" fill="#8BB7F0" />
    </g>
    <defs>
      <clipPath id="clip0_1584_10112">
        <rect
          width="80"
          height="80"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
