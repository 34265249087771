export const CredentialsIcon = () => (
  <svg
    width="80"
    height="81"
    viewBox="0 0 80 81"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.5 68C4.5 68 2.5 66 2.5 63V18C2.5 15 4.5 13 7.5 13H72.5C75.5 13 77.5 15 77.5 18V63C77.5 66 75.5 68 72.5 68H7.5Z"
      fill="#C2E8FF"
    />
    <path
      d="M72.5 15.5C74 15.5 75 16.5 75 18V63C75 64.5 74 65.5 72.5 65.5H7.5C6 65.5 5 64.5 5 63V18C5 16.5 6 15.5 7.5 15.5H72.5ZM72.5 10.5H7.5C3.5 10.5 0 14 0 18V63C0 67 3.5 70.5 7.5 70.5H72.5C76.5 70.5 80 67 80 63V18C80 14 76.5 10.5 72.5 10.5Z"
      fill="#C2E8FF"
    />
    <path
      d="M50 40.5H70V45.5H50V40.5ZM50 50.5H65V55.5H50V50.5Z"
      fill="#7496C4"
    />
    <path
      d="M27.5 48.5L20 52.5L21 44L15 37.5L23.5 36.5L27.5 28.5L31.5 36.5L40 37.5L34 44L35 52.5L27.5 48.5Z"
      fill="#FFEEA3"
    />
    <path
      d="M38.5 57L27.5 51L16.5 57L18.5 44.5L9.5 36L22 34L27.5 23L33 34L45.5 36L36.5 44.5L38.5 57ZM27.5 45.5L32 48L31 43L34.5 39.5L29.5 39L27.5 34.5L25.5 38.5L20.5 39L24 42.5L23 47.5L27.5 45.5Z"
      fill="#FFEEA3"
    />
  </svg>
);
